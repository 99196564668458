<template>
  <div>
    <b-modal
      hide-footer
      size="lg"
      id="modalEnviarMensagem"
      title="Enviar Mensagem"
    >
      <!-- Modal Header -->
      <template #modal-header>
        <h5 class="modal-title">Enviar Mensagem</h5>
        <div class="modal-actions">
          <feather-icon
            icon="MinusIcon"
            class="cursor-pointer"
            @click="$bvModal.hide('modalEnviarMensagem')"
          />
          <feather-icon
            icon="XIcon"
            class="ml-1 cursor-pointer"
            @click="fecharModal"
          />
        </div>
      </template>

      <b-alert :variant="getErrors.color" v-if="getErrors.hasMessage" show="">
        <div
          class="alert-body"
          v-for="(error, index) in getErrors.messageArray"
          :key="index"
        >
          {{ error[0] }}
        </div>

        <div class="alert-body">{{ getErrors.message }}</div>
      </b-alert>

      <!-- Modal: Body -->
      <b-form>
        <div class="demo-inline-spacing">
          <span class="pl-2 mr-2">Para: </span>
          <b-form-checkbox
            id="checkbox-1"
            v-model="sistema"
            class="custom-control-primary"
            >Sistema
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-2"
            v-model="whatsapp"
            class="custom-control-primary"
            >Whatsapp
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-3"
            v-model="email"
            class="custom-control-primary"
            >Email
          </b-form-checkbox>
          <b-form-checkbox
            id="checkbox-4"
            v-model="sms"
            class="custom-control-primary"
            disabled
            >SMS
          </b-form-checkbox>
        </div>
        <hr class="mb-25" />
        <b-form-group
          label-cols="1"
          label-cols-lg="1"
          label="Assunto:"
          label-for="input-default"
          class="pl-2 mb-0"
        >
          <b-form-input
            class="borderless-input"
            id="input-default"
            v-model="titulo"
          />
        </b-form-group>

        <hr class="mt-25" />
        <b-form-textarea
          placeholder="Mensagem"
          v-model="mensagem"
        ></b-form-textarea>
        
          <b-form-input
            class="mt-1"
            v-model="link"
            placeholder="Link"
          />
      
      </b-form>
      <hr />
      <b-row align-h="between" class="px-1">
        <b-button @click="fecharModal">Cancelar</b-button>
        <b-button @click="enviarMensagem" variant="primary"
          ><b-spinner small v-if="spinner" />
          <span v-if="spinner">Aguarde...</span>
          <span v-else>Enviar</span></b-button
        >
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BDropdown,
  BSpinner,
  BRow,
  BCol,
  BDropdownItem,
  VBToggle,
  BForm,
  BFormInput,
  BAvatar,
  BDropdownDivider,
  BButton,
  BFormGroup,
  BFormTextarea,
  BAlert,
  BFormCheckbox,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapState } from "vuex";

export default {
  directives: { "b-toggle": VBToggle, Ripple },
  components: {
    // BSV
    BFormCheckbox,
    BDropdown,
    BDropdownItem,
    BForm,
    BFormInput,
    BAvatar,
    BDropdownDivider,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BFormTextarea,
    BAlert,
    BSpinner,
    ToastificationContent,
  },

  data() {
    return {
      sistema: false,
      whatsapp: false,
      email: false,
      sms: false,
      titulo: "",
      mensagem: "",
      link: "",
      spinner: false,
      submit: false,
      getErrors: {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      },
      errorEmails: [],
    };
  },

  computed: {
    ...mapState({
      filtros: (state) => state.mensagens,
    }),
  },

  methods: {
    enviarMensagem() {
      this.spinner = true;
      this.submit = true;

      let payload = {
        recorrencia_assinatura: this.filtros.recorrencia_assinatura,
        tipo_data: this.filtros.tipo_data,
        tipo_assinatura: this.filtros.tipo_assinatura,
        id_company: this.filtros.id_company,
        data_inicial: this.filtros.data_inicial,
        data_final: this.filtros.data_final,
        sistema: this.sistema,
        whatsapp: this.whatsapp,
        sms: this.sms,
        email: this.email,
        titulo: this.titulo,
        link: this.link,
        mensagem: this.mensagem,
      };

      this.$http
        .post(`adm/notificacoes`, payload)
        .then((res) => {
          if (res.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Mensagem Enviada com Sucesso!",
                icon: "InfoIcon",
                variant: "success",
              },
            });
            this.resetaErros();
            this.resetarCampos();
            this.fecharModal();
          }
        })
        .catch((error) => {
          let res = this.$responseMessages.getMessage(error, null);
          this.getErrors = res;
        })
        .finally(() => {
          this.spinner = false;
          this.submit = false;
        });
    },
    validateEmail(email) {
      var erro = false;

      email.filter((verifica) => {
        var re = /\S+@\S+\.\S+/;
        var ver = re.test(verifica);

        if (!ver) {
          this.errorEmails.push(verifica);
          erro = true;
        }
      });

      return erro;
    },
    fecharModal() {
      this.resetarCampos();
      this.resetaErros();
      this.$bvModal.hide("modalEnviarMensagem");
    },
    resetarCampos() {
      (this.sistema = false),
        (this.whatsapp = false),
        (this.sms = false),
        (this.email = false),
        (this.titulo = ""),
        (this.link = ""),
        (this.mensagem = "");
    },

    resetaErros() {
      this.getErrors = {
        hasMessage: false,
        color: "danger",
        message: "",
        messageArray: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.borderless-input {
  border: none;
  border-radius: 0;
  background-color: transparent; /* Define o fundo como transparente */
  box-shadow: none; /* Remove a sombra */
}

.borderless-input:focus {
  background-color: transparent; /* Mantém o fundo transparente quando o input está em foco */
  box-shadow: none; /* Remove a sombra quando o input está em foco */
}
</style>
