<template>
  <div>
    <b-table
      striped
      hover
      small
      responsive
      :items="assinaturas"
      :fields="fields"
    >
      
    </b-table>
  </div>
</template>

<script>
import { BCard, BTable, BButton } from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BCard,
    BTable,
    BButton,
  },
  data() {
    return {
      assinatura: [],
      fields: [
        { key: "id", label: "ID", thStyle: { width: "5%" } },
        {
          key: "nome",
          label: "Cliente",
          sortable: true,
        },
        {
          key: "email",
          label: "E-mail",
          sortable: true,
        },
      ],
    };
  },
  computed: {
    ...mapState({
      assinaturas: (state) => state.mensagens.assinaturas,
    }),
  },
};
</script>

<style>
</style>