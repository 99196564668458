<template>
  <div>
    <b-row class="mb-1">
      <b-col>
        <label>Tipo Assinatura</label>
        <b-input-group size="sm">
          <b-form-select
            id="tipo"
            v-model="assinatura_tipo"
            :options="tipo_assinatura"
            text-field="text"
            value-field="value"
            style="height: 32px"
            @change="alterouTipoAssinatura"
          >
          </b-form-select>
        </b-input-group>
      </b-col>
      <b-col>
        <label>Tipo Plano</label>
        <b-input-group size="sm">
          <b-form-select
            :disabled="selecionouTipoAssinaturaGratis"
            id="tipo"
            v-model="assinatura_recorrencia"
            :options="recorrencia_assinatura"
            text-field="text"
            value-field="value"
            style="height: 32px"
            @change="alterouRecorrenciaAssinatura"
          >
          </b-form-select>
        </b-input-group>
      </b-col>

      <b-col md="5">
        <b-form-group
          label="Tipo Data"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="tipo"
              v-model="data_tipo"
              :options="tipo_data"
              text-field="text"
              value-field="value"
              style="height: 32px"
              @change="alterouTipoData"
            >
            </b-form-select>
            <b-form-datepicker
              id="data_inicial"
              size="sm"
              v-model="data_inicial"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              hide-header
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
            <b-form-datepicker
              id="data_final"
              size="sm"
              v-model="data_final"
              :date-format-options="{
                day: 'numeric',
                month: 'numeric',
                year: 'numeric',
              }"
              close-button
              hide-header
              weekday-header-format="narrow"
              offset="-25"
              dropdown
              label-close-button="Fechar"
              label-reset-button="Apagar Data"
              labelHelp=""
              locale="pt-BR"
              placeholder="Data"
              reset-button
              @input="alterouData"
            />
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <label label-for="busca">Busca</label>
        <b-form-group
          id="busca"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm" prepend="ID">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="limpaBusca">
                Limpar
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col align-self="end" class="text-center">
        <b-button block variant="primary" size="sm" style="padding-bottom: 8px; padding-top: 8px; margin-bottom: 2px" @click="buscaDados">
          Buscar
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import moment from "moment";
import Ripple from "vue-ripple-directive";
import {
  BIcon,
  BButton,
  BListGroup,
  BListGroupItem,
  VBToggle,
  BFormDatepicker,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BFormGroup,
  BFormSelect,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  directives: { Ripple, "b-toggle": VBToggle },
  components: {
    BIcon,
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
    vSelect,
    VBToggle,
    BFormDatepicker,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    BInputGroupPrepend,
    BDropdown,
    BDropdownItem,
    BFormInput,
    BFormGroup,
    BFormSelect,
  },

  data() {
    return {
      selecionouTipoAssinaturaGratis: false,
      filtroSelecionado: "ID",
      filter: "",
      assinatura_tipo: 0,
      assinatura_recorrencia: 0,
      data_tipo: 'data_inicio',
      data_inicial: this.$getDate.primeiroDiaMes(),
      data_final: this.$getDate.ultimoDiaMes(),
      fornSelIndex: "",
      perfectScrollbarSettings: {
        maxScrollbarLength: 60,
      },
      tipo_assinatura: [
        { value: 0, text: "Todos" },
        { value: 1, text: "Teste Grátis" },
        { value: 2, text: "Bronze" },
        { value: 3, text: "Prata" },
        { value: 4, text: "Ouro" },
        { value: 5, text: "Diamante" },
      ],
      tipo_data: [
        { value: 'data_inicio', text: "Data Início" },
        { value: 'data_cancelamento', text: "Data Cancelamento" },
        { value: 'data_expiracao', text: "Data Expiração" },
      ],
      recorrencia_assinatura: [
        { value: 0, text: "Todos" },
        { value: 2, text: "Mensal" },
        { value: 3, text: "Anual" },
      ],
    };
  },

  computed: {},
  mounted() {
    this.limpaFiltros();
  },
  destroyed(){
    this.$store.commit("mensagens/STORE_ASSINATURAS", []);
  },
  /* watch: {
    filter() {
      this.buscaPorId();
    },
  }, */

  methods: {
    alterouTipoAssinatura() {
      this.$store.commit("mensagens/STORE_TIPO_ASSINATURA", this.assinatura_tipo);
      this.assinatura_tipo === 1 ? (this.assinatura_recorrencia = 0, this.selecionouTipoAssinaturaGratis = true) : this.selecionouTipoAssinaturaGratis = false
      this.$store.commit("mensagens/STORE_RECORRENCIA_ASSINATURA", this.assinatura_recorrencia);
    },
    alterouRecorrenciaAssinatura() {
      this.$store.commit("mensagens/STORE_RECORRENCIA_ASSINATURA", this.assinatura_recorrencia);
    },
    alterouTipoData() {
      this.$store.commit("mensagens/STORE_TIPO_DATA", this.data_tipo);
    },
    alterouData() {
      let payload = {
        data_inicial: this.data_inicial,
        data_final: this.data_final,
      };
      this.$store.commit("mensagens/STORE_FILTRO_DATA", payload);
    },
    limpaBusca() {
      this.filter = "";
      this.buscaDados()
    },
    /* buscaPorId() {
      this.$store.commit("mensagens/STORE_ID_COMPANY", this.filter);
      this.$store.dispatch("mensagens/listaAssinaturas");
    }, */
    
    buscaDados() {
      this.$store.commit("mensagens/STORE_ID_COMPANY", this.filter);
      this.$store.dispatch("mensagens/listaAssinaturas");
    },
    limpaFiltros() {
      this.filter = "";
      this.assinatura_tipo = 0;
      this.assinatura_recorrencia = 0;
      this.data_tipo = 'data_inicio';
      this.data_inicial = this.$getDate.primeiroDiaMes();
      this.data_final = this.$getDate.ultimoDiaMes();
      this.$store.commit("mensagens/STORE_TIPO_ASSINATURA", this.assinatura_tipo);
      this.$store.commit("mensagens/STORE_TIPO_DATA", this.data_tipo);
      let payload = {
        data_inicial: this.data_inicial,
        data_final: this.data_final,
      };
      this.$store.commit("mensagens/STORE_FILTRO_DATA", payload);
      this.$store.commit("mensagens/STORE_ID_COMPANY", this.filter);
    },
  },

  filters: {
    formataData(data) {
      if (!data) return;

      let d = data.split("-");
      return `${d[2]}/${d[1]}/${d[0]}`;
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style>
.sidebar-left {
  width: 290px;
}
.todo-application .content-area-wrapper .sidebar .todo-sidebar {
  width: 282px !important;
}
</style>
