<template>
  <div>
    <b-button :disabled="userPermissions < 3" variant="gradient-primary" class="mb-1" @click="abrirModalEnviarMensagem()">Enviar Mensagem</b-button>
    <b-button variant="gradient-success" class="ml-1 mb-1" @click="exportarExcel()">Exportar Excel</b-button>
    <b-card>
      <filtros-enviar-mensagem/>
      <lista-assinaturas/>
      <modal-enviar-mensagem/>
    </b-card>
  </div>
</template>

<script>
import { BCard, BButton } from "bootstrap-vue";
import FiltrosEnviarMensagem from './FiltrosEnviarMensagem.vue';
import ListaAssinaturas from './ListaAssinaturas.vue';
import ModalEnviarMensagem from './ModalEnviarMensagem.vue';
import * as js2excel from 'js2excel';
import {mapGetters, mapState} from "vuex";
import moment from "moment";

export default {
  components: {
    BCard,
    BButton,
    FiltrosEnviarMensagem,
    ListaAssinaturas,
    ModalEnviarMensagem
  },

  computed: {
    ...mapState({
      assinaturas: state => state.mensagens.assinaturas,
    }),
    ...mapGetters({
      userPermissions: "user/userPermissions"
    })
  },

  methods: {
    abrirModalEnviarMensagem() {
      this.$bvModal.show('modalEnviarMensagem')
    },
    exportarExcel() {

      if(!this.assinaturas) {
        return
      }

      let res = []

      this.assinaturas.forEach( (v) => {

        let celular = v.celular_um ? `55${v.celular_um}` : `55${v.celular_dois}`
        celular = celular.replace(/[^0-9]/g, '');

        if(celular.length > 11) {
          res.push({
            'nome': v.nome,
            'e-mail': v.email,
            'numero': celular
          })
        }
      })

      js2excel.json2excel({
        data: res,
        name: 'Lista-assinantes-smart',
        formateDate: moment().format('DD/MM/YYYY')
      })

    }
  }
};
</script>

<style>
</style>